import React from "react";
const Demo  = ({ className, title, name, scan, projectId, ...rest }) => {
  return (
<>
  <div className={className} {...rest} style={{position: "relative", top:"-1px", height:"101%", zIndex:"100"}}> 
    <div className="container mb-0 pb-0">
      <div className="row">
        <div className="col-12 text-center">
          {title &&
          <h3 className="mb-5">{title ? title : ""}</h3>
          }
          {name &&
            <p className="mb-15 w-lg-50 w-100 m-auto">{name}</p>
          }
          <div
            className="rounded-8 mt-10"
            >
              <div
                id="digma-embed"
                className="digma"
                data-projectid={projectId}
              ></div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</>
  );
};

export default Demo;
